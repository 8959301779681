@charset "UTF-8";
/*
Global Variables
Contents:

1.  Imports
2.  Colour variables
3.  Environment variables
4.  Theme colours
5.  Radii and other global css3 style properties
6.  Link colours
7.  Tables
8.  Max page widths applied to container elements
*/
/*
1.  Imports
*/
/*
 * This file contains all breakpoints used by respond() SCSS function and
 * is also converted to JSON for use in rework-pure-grids
 * NOTE: Leave comma after last item for proper JSON conversion
 *
 * Pixel sizes:
 *	xs: 320px
 *	sm:  568px
 *	md: 768px
 *	lg:  1024px
 *  xl: 1280px
 *  xxl: 1440px
 *  xxxl: 1800px
 */
/*
2.  Colour variables
Put the colours from the design here. When naming your colours
be sure to use  http://chir.ag/projects/name-that-color to get
a proper colour name
*/
/* Primary */
/* Secondary */
/* Support Tints */
/* Support Dark */
/* Background Colours for Alert Banners */
/*
3.  Environment variables
Environment variables. Note some projects put all css into assets,
so the path needs to be able to find the css from there
*/
/*
4.  Theme colours
Make sure that the colours assigned here are assigned using the
colour variables above
*/
/* Primary colours */
/* Secondary Colours */
/* Support Tints */
/* Support - Dark */
/* State colours */
/* Page Colours */
/* Extra Blues */
/*
5.  Radii and other global css3 style properties
*/
/*
6.  Link colours
Make sure that the colours assigned here are assigned using the
colour variables above
*/
/*
7.  Tables
*/
/*
8.  Max page widths applied to container elements
*/
:root {
  --colours-primary-light-blue: #00a7e1;
  --colours-sbn-light-grey: #f3f4f3;
  --colours-primary-pinpoint-red: #cf2659;
  --colours-primary-brand-blue: #0077af;
  --colours-secondary-green-light: #00a8a2;
  --colours-secondary-gold-dark: #84752d;
  --colours-secondary-gold-light: #c7c091;
  --colours-secondary-green-dark: #007772;
  --colours-primary-white: #fff;
  --colours-secondary-blue-grey: #6f94aa;
  --colours-secondary-cool-grey: #c6c8c6;
  --colours-secondary-charcoal-grey: #3e555f;
  --colours-support-gold-light-tint: #f9f8f3;
  --colours-support-gold-dark-tint: #eef3eb;
  --colours-primary-black: #000;
  --colours-support-light-blue-tint: #ecf7f8;
  --colours-support-light-blue: #09A6DF;
  --colours-support-cool-grey-tint: #f9f9f9;
  --colours-support-dark-blue: #022231;
  --colours-support-dark-charcoal: #404042;
  --colours-support-dark-brand-blue: #005279;
  --colours-alto: #d1d1d1 ;
}

/*
	Fixes the scrollbar making content jump issue when an option is selected in nat lang
*/
html {
  overflow-y: scroll;
}

body {
  padding-right: 0px !important;
}

/*
# Colours - primary palette

SectionTemplate: BenManu/StyleGuide/Includes/SGColorPalette

$primary-color - #E20057;
$secondary-color - #0077AF;

Styleguide 1.1
*/
/*
# Colours - secondary palette

SectionTemplate: BenManu/StyleGuide/Includes/SGColorPalette

$color-indian-khaki - #C1BA85;
$color-pesto - #84752d;
$color-persian-green - #00a8a2;
$color-pine-green - #007772;
$color-fiord - #3E555F;
$color-bermuda-gray - #6f94aa;
$color-pumice - #c6c8c6;

Styleguide 1.1.1
*/
/*
## Font Test

<div class="test">
	<h3>Default</h3>

	<p style="font-size:10px">10px the quick brown fox jumps over the lazy dog ĀāĒēĪīŌōŪū THE QUICK BROWN FOX JUMPS OVER THE LAZY DOG 0123456789 !@#$%^&*()?"':;,.</p>
	<p style="font-size:15px">15px the quick brown fox jumps over the lazy dog ĀāĒēĪīŌōŪū THE QUICK BROWN FOX JUMPS OVER THE LAZY DOG 0123456789 !@#$%^&*()?"':;,.</p>
	<p style="font-size:20px">20px the quick brown fox jumps over the lazy dog ĀāĒēĪīŌōŪū THE QUICK BROWN FOX JUMPS OVER THE LAZY DOG 0123456789 !@#$%^&*()?"':;,.</p>
	<p style="font-size:30px">30px the quick brown fox jumps over the lazy dog ĀāĒēĪīŌōŪū THE QUICK BROWN FOX JUMPS OVER THE LAZY DOG 0123456789 !@#$%^&*()?"':;,.</p>
	<p style="font-size:40px">40px the quick brown fox jumps over the lazy dog ĀāĒēĪīŌōŪū THE QUICK BROWN FOX JUMPS OVER THE LAZY DOG 0123456789 !@#$%^&*()?"':;,.</p>
</div>

Styleguide 1.2.4
*/
/*
* A file for Custom mixins and placeholders
*/
/**
* Utility classes are short modifiers used across components
* These should be used sparingly. In most cases a component is a better choice.
*/
.metadata {
  font-size: 11px;
}

/**
 * Screen reader helper classes
 */
.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}
.sr-only--focusable:active, .sr-only--focusable:focus {
  position: static;
  width: auto;
  height: auto;
  margin: 0;
  overflow: visible;
  clip: auto;
}

.printonly {
  display: none;
}
@media print {
  .printonly {
    display: block;
  }
}
@media print {
  .printonly {
    display: block;
  }
}

html body .hidden-base {
  display: none;
}
@media print {
  html body .hidden-base {
    display: none;
  }
}
@media screen and (min-width: 21em) {
  html body .hidden-xs {
    display: none;
  }
}
@media print {
  html body .hidden-xs {
    display: none;
  }
}
@media screen and (max-width: 20.99em) {
  html body .hidden-xs--max {
    display: none;
  }
}
@media print {
  html body .hidden-xs--max {
    display: none;
  }
}
@media screen and (min-width: 35.5em) {
  html body .hidden-sm {
    display: none;
  }
}
@media print {
  html body .hidden-sm {
    display: none;
  }
}
@media screen and (max-width: 35.49em) {
  html body .hidden-sm--max {
    display: none;
  }
}
@media print {
  html body .hidden-sm--max {
    display: none;
  }
}
@media screen and (min-width: 48em) {
  html body .hidden-md {
    display: none;
  }
}
@media print {
  html body .hidden-md {
    display: none;
  }
}
@media screen and (max-width: 47.99em) {
  html body .hidden-md--max {
    display: none;
  }
}
@media print {
  html body .hidden-md--max {
    display: none;
  }
}
@media screen and (min-width: 64em) {
  html body .hidden-lg {
    display: none;
  }
}
@media print {
  html body .hidden-lg {
    display: none;
  }
}
@media screen and (max-width: 63.99em) {
  html body .hidden-lg--max {
    display: none;
  }
}
@media print {
  html body .hidden-lg--max {
    display: none;
  }
}
@media screen and (min-width: 80em) {
  html body .hidden-xl {
    display: none;
  }
}
@media print {
  html body .hidden-xl {
    display: none;
  }
}
@media screen and (max-width: 79.99em) {
  html body .hidden-xl--max {
    display: none;
  }
}
@media print {
  html body .hidden-xl--max {
    display: none;
  }
}
@media screen and (min-width: 90em) {
  html body .hidden-xxl {
    display: none;
  }
}
@media print {
  html body .hidden-xxl {
    display: none;
  }
}
@media screen and (max-width: 89.99em) {
  html body .hidden-xxl--max {
    display: none;
  }
}
@media print {
  html body .hidden-xxl--max {
    display: none;
  }
}
@media screen and (min-width: 112.5em) {
  html body .hidden-xxxl {
    display: none;
  }
}
@media print {
  html body .hidden-xxxl {
    display: none;
  }
}
@media screen and (max-width: 112.49em) {
  html body .hidden-xxxl--max {
    display: none;
  }
}
@media print {
  html body .hidden-xxxl--max {
    display: none;
  }
}

.tools-carousel {
  position: relative;
  margin-left: auto;
  margin-right: auto;
  max-width: 100%;
}
@media screen and (min-width: 64em) {
  .tools-carousel {
    padding: 0;
    max-width: 1080px;
  }
}
@media print {
  .tools-carousel {
    padding: 0;
    max-width: 1080px;
  }
}
.tools-carousel--overflow {
  min-height: 100vh;
  overflow: hidden;
  padding-bottom: 20px;
}
@media screen and (min-width: 48em) {
  .tools-carousel--overflow {
    padding-bottom: 100px;
  }
}
@media print {
  .tools-carousel--overflow {
    padding-bottom: 100px;
  }
}
@media screen and (min-width: 64em) {
  .tools-carousel--overflow {
    padding-bottom: 123px;
  }
}
@media print {
  .tools-carousel--overflow {
    padding-bottom: 123px;
  }
}
.tools-carousel__viewport {
  overflow: hidden;
  width: 100%;
}
.tools-carousel--overflow .tools-carousel__viewport {
  overflow: visible;
  position: relative;
  z-index: 2;
}
.tools-carousel--overflow .tools-carousel__viewport__wrapper {
  position: relative;
  z-index: 3;
}
.tools-carousel__viewport *.is-draggable {
  cursor: move;
  cursor: grab;
}
.tools-carousel__viewport.is-dragging {
  cursor: grabbing;
}
.tools-carousel__container {
  display: flex;
  width: 100%;
  user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  -webkit-tap-highlight-color: transparent;
}
.tools-carousel__slide {
  position: relative;
  display: block;
  min-width: 100%;
  width: 100%;
  padding: 0 20px;
  visibility: hidden;
}
.tools-carousel__slide:focus {
  outline: none;
}
.tools-carousel--simple .tools-carousel__slide {
  visibility: visible;
  margin: 0 10px;
}
.tools-carousel__slide.is-selected {
  visibility: visible;
}
@media screen and (min-width: 48em) {
  .tools-carousel__slide {
    padding: 0 64px;
  }
}
@media print {
  .tools-carousel__slide {
    padding: 0 64px;
  }
}
.tools-carousel__slide__inner {
  position: relative;
  overflow: hidden;
  width: 100%;
}
.tools-carousel--overflow .tools-carousel__slide__inner {
  overflow: visible;
}
.tools-carousel__slide__content {
  display: flex;
  flex-wrap: wrap;
  padding: 30px 0 0;
}
@media screen and (min-width: 48em) {
  .tools-carousel__slide__content {
    padding: 50px 0 0;
  }
}
@media print {
  .tools-carousel__slide__content {
    padding: 50px 0 0;
  }
}
@media screen and (min-width: 64em) {
  .tools-carousel__slide__content {
    padding: 100px 0 0 0;
  }
}
@media print {
  .tools-carousel__slide__content {
    padding: 100px 0 0 0;
  }
}
.tools-carousel__slide__text {
  font-family: "Gustan", Arial, sans-serif;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  color: #404042;
  width: 100%;
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active), (min-width: 768px) {
  .tools-carousel__slide__text {
    /* IE10+ CSS styles go here */
    flex: 50%;
  }
}
.tools-carousel__slide__text h2 {
  font-size: 20px;
  font-weight: bold;
  line-height: 1.1;
  margin: 0 0 15px;
}
@media screen and (min-width: 48em) {
  .tools-carousel__slide__text h2 {
    font-size: 24px;
    margin: 0 0 30px;
  }
}
@media print {
  .tools-carousel__slide__text h2 {
    font-size: 24px;
    margin: 0 0 30px;
  }
}
.tools-carousel__slide__text__icon {
  display: none;
  width: 55px;
  height: 55px;
  background-repeat: no-repeat;
}
@media screen and (min-width: 48em) {
  .tools-carousel__slide__text__icon {
    display: block;
  }
}
@media print {
  .tools-carousel__slide__text__icon {
    display: block;
  }
}
.tools-carousel__slide__text__content {
  flex: 1;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
@media screen and (min-width: 48em) {
  .tools-carousel__slide__text__content {
    margin-left: 20px;
  }
}
@media print {
  .tools-carousel__slide__text__content {
    margin-left: 20px;
  }
}
.tools-carousel__slide__text__wrapper {
  font-size: 14px;
  margin-bottom: 25px;
}
@media screen and (min-width: 48em) {
  .tools-carousel__slide__text__wrapper {
    font-size: 16px;
    display: flex;
    flex-wrap: wrap;
  }
}
@media print {
  .tools-carousel__slide__text__wrapper {
    font-size: 16px;
    display: flex;
    flex-wrap: wrap;
  }
}
@media screen and (min-width: 64em) {
  .tools-carousel__slide__text__wrapper {
    padding-right: 70px;
  }
  .tools-carousel__slide__text__wrapper:last-of-type {
    margin-bottom: 0;
  }
}
@media print {
  .tools-carousel__slide__text__wrapper {
    padding-right: 70px;
  }
  .tools-carousel__slide__text__wrapper:last-of-type {
    margin-bottom: 0;
  }
}
.tools-carousel__slide__text__wrapper strong {
  font-weight: bold;
}
.tools-carousel__slide__text__wrapper p {
  width: 100%;
  margin: 0 0 10px;
  line-height: 24px;
}
.tools-carousel__slide__text__wrapper p:last-of-type {
  margin: 0;
}
.tools-carousel__slide__image {
  max-width: 570px;
  margin: 0 auto;
}
.tools-carousel__slide__image picture {
  display: block;
  padding: 25px;
}
.tools-carousel__slide__image img {
  width: 100%;
  height: 100%;
  font-family: "object-fit: cover;";
  object-fit: cover;
  box-shadow: 0 0 30px 0 rgba(90, 90, 90, 0.2);
  border-radius: 10px;
}
.tools-carousel__slide__skip {
  text-decoration: underline;
  font-size: 12px;
  line-height: 14px;
  font-family: "FiraSans";
  font-weight: normal;
  height: auto;
}
@media screen and (min-width: 48em) {
  .tools-carousel__slide__skip {
    font-size: 14px;
  }
}
@media print {
  .tools-carousel__slide__skip {
    font-size: 14px;
  }
}
.tools-carousel__slide__skipcontainer {
  font-size: 12px;
  line-height: 14px;
  order: 2;
  width: 100%;
  text-align: center;
  margin: 0 0 20px;
}
@media screen and (min-width: 48em) {
  .tools-carousel__slide__skipcontainer {
    font-size: 14px;
    margin-bottom: 30px;
  }
}
@media print {
  .tools-carousel__slide__skipcontainer {
    font-size: 14px;
    margin-bottom: 30px;
  }
}
@media screen and (min-width: 64em) {
  .tools-carousel__slide__skipcontainer {
    margin-bottom: 48px;
  }
}
@media print {
  .tools-carousel__slide__skipcontainer {
    margin-bottom: 48px;
  }
}
.tools-carousel__navigation {
  width: 100%;
  position: relative;
  min-height: 50px;
  background-color: #FFF;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 19px 20px 30px;
  outline: none;
}
.tools-carousel__navigation:focus {
  outline: none;
}
@media screen and (min-width: 48em) {
  .tools-carousel__navigation {
    display: block;
    min-height: 100px;
    padding: 20px 20px 30px;
  }
}
@media print {
  .tools-carousel__navigation {
    display: block;
    min-height: 100px;
    padding: 20px 20px 30px;
  }
}
.tools-carousel--overflow .tools-carousel__navigation {
  z-index: 2;
}
.tools-carousel__navigation__dots {
  order: 2;
  display: flex;
  margin: 28px auto 0;
}
@media screen and (min-width: 48em) {
  .tools-carousel__navigation__dots {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    margin: 0;
  }
}
@media print {
  .tools-carousel__navigation__dots {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    margin: 0;
  }
}
.tools-carousel__navigation__buttons {
  order: 1;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}
@media screen and (min-width: 48em) {
  .tools-carousel__navigation__buttons {
    display: inline-block;
  }
}
@media print {
  .tools-carousel__navigation__buttons {
    display: inline-block;
  }
}
.tools-carousel__dot {
  background-color: transparent;
  position: relative;
  outline: 0;
  border: 0;
  height: 30px;
  width: 30px;
  padding: 15px 8px;
  margin: 0;
  display: flex;
  align-items: center;
}
.tools-carousel__dot:after {
  background-color: #FFF;
  height: 8px;
  width: 8px;
  border-radius: 8px;
  border: 1px solid #0077AF;
  content: " ";
  transition: all 250ms;
}
@media screen and (min-width: 48em) {
  .tools-carousel__dot:after {
    height: 12px;
    width: 13px;
  }
}
@media print {
  .tools-carousel__dot:after {
    height: 12px;
    width: 13px;
  }
}
.tools-carousel__dot.is-selected:after {
  background-color: #0077AF;
  opacity: 1;
}
.tools-carousel__button {
  cursor: pointer;
  touch-action: manipulation;
  z-index: 1;
  width: 100%;
  font-size: 14px;
  line-height: 14px;
  padding: 13px 38px;
  height: auto;
  font-weight: 800;
  letter-spacing: normal;
  border-radius: 3px;
  text-transform: uppercase;
  min-width: 148px;
}
@media screen and (min-width: 48em) {
  .tools-carousel__button {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: auto;
    padding: 13px 10px;
  }
}
@media print {
  .tools-carousel__button {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: auto;
    padding: 13px 10px;
  }
}
@media screen and (min-width: 64em) {
  .tools-carousel__button {
    padding: 13px 37px;
  }
}
@media print {
  .tools-carousel__button {
    padding: 13px 37px;
  }
}
.tools-carousel__button:disabled {
  cursor: default;
  opacity: 0.3;
}
.tools-carousel__button--prev {
  margin-bottom: 8px;
  left: 20px;
  order: 1;
}
@media screen and (min-width: 48em) {
  .tools-carousel__button--prev {
    margin-bottom: 10px;
  }
}
@media print {
  .tools-carousel__button--prev {
    margin-bottom: 10px;
  }
}
@media screen and (min-width: 64em) {
  .tools-carousel__button--prev {
    left: 58px;
    margin: 0;
  }
}
@media print {
  .tools-carousel__button--prev {
    left: 58px;
    margin: 0;
  }
}
.tools-carousel__button--prev svg {
  position: relative;
  left: -1px;
}
.tools-carousel__button--done {
  order: 2;
  text-transform: none;
  right: 20px;
  font-size: 16px;
  font-weight: 500;
  padding: 17px 32px;
}
@media screen and (max-width: 767px) {
  .tools-carousel__button--done {
    top: 100%;
    transform: none;
    width: 100%;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
}
@media screen and (min-width: 48em) {
  .tools-carousel__button--done {
    padding: 17px 13px;
  }
}
@media print {
  .tools-carousel__button--done {
    padding: 17px 13px;
  }
}
@media screen and (min-width: 64em) {
  .tools-carousel__button--done {
    right: 60px;
    font-size: 18px;
    padding: 17px 32px;
  }
}
@media print {
  .tools-carousel__button--done {
    right: 60px;
    font-size: 18px;
    padding: 17px 32px;
  }
}
.tools-carousel__button--next {
  order: 2;
  right: 19px;
}
@media screen and (min-width: 64em) {
  .tools-carousel__button--next {
    right: 59px;
  }
}
@media print {
  .tools-carousel__button--next {
    right: 59px;
  }
}